<template>
    <div id="portfolio">

        <b-container>

        <b-row v-for="row in rows_cols">
            <b-col v-for="item in row">
                <b-card                
                    @click="cardclick(item.route)"                     
                    :title="`${item.title}`"
                    :img-src="require(`@/assets/${item.image}`)"
                    img-alt="Image"
                    img-top
                    tag="article"
                    style="max-width: 20rem;"
                    class="mb-2"
                    >
                    <!-- <b-card-text>
                    {{item.text}}
                    </b-card-text> -->
                    
            </b-card>              
            </b-col>            
        </b-row>


        </b-container>

    </div>
</template>

<script>

import portfolios from '../data/portfoliolist.json';

export default {
    name:'Portfolio',
    methods:{
      cardclick: function(id){

        this.$router.push('/portfolio/' + id);

          console.log("cardclick " + id);
      }
  },
  data() {
      return {
        portfolio_list : portfolios,
        colcount:3
      }
  },
    computed:{
      rows_cols: function(){
        var rows = [];
        var total = this.portfolio_list.length;

        var rowcount  = Math.ceil( total / this.colcount );
                
        for(var r=0;r<rowcount;r++){                
            var row = [];

            for(var c=0;c<this.colcount;c++){
                var index= (r*this.colcount) + c;

                if(index == total ) break;

                row.push(this.portfolio_list[index]);                    
            }               
            rows.push(row);
        }  
        
       
        return rows;
      }
  }
}
</script>

<style scoped>

#portfolio{
    padding-top:40px;
    padding-bottom:40px;
}

.card{
    cursor: pointer;
}

</style>

