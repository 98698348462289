<template>
    
<div id="footer">
    
<b-container>

<b-row>
    <b-col>
        <b-img fluid src="../assets/logo-montserrat-white-back-wit.png"></b-img>
    </b-col>


    <b-col>
        <p><b>Office</b></p>
        <div class="footer_text">
            <div>De Maalderij 14</div>
            <div>3829AZ Hooglanderveen</div>
            <div>The Netherlands</div>
            <div>contact@lab4242.com</div>
            <div>+31 621805942</div>
        </div>
    </b-col>


    <b-col>
        <p><b>Links</b></p>
        <div class="footer_text">
            <div><router-link to="/about">About Us</router-link></div>
            <div><router-link to="/contact">Contact</router-link></div>                              
        </div>
    </b-col>

    <b-col>
        <p><b>Socials</b></p>
        <div class="socials">
            <a href="https://www.facebook.com/LAB4242" target="_blank" class="fa fa-facebook-f" ></a>
            <a href="https://www.twitter.com/LAB4242" target="_blank" class="fa fa-twitter" ></a>
            <a href="https://www.linkedin.com/company/LAB4242" target="_blank" class="fa fa-linkedin" ></a>           
        </div>
    </b-col>

  </b-row>


</b-container>



</div>


</template>

<script>
export default {
  name: 'Footer'  
}
</script>


<style scoped>

@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

#footer{
    background-color: #1a1a1f;
    color:#ffffff;
    text-align: left;
    padding-top: 40px;
    padding-bottom: 40px;
}

.footer_text{
    color:#aeaeae;
    font-size:17px;
    text-align: left;   
}

.footer_text a:visited, .footer_text a:link{
    color:#aeaeae;
}

a:hover{
    text-decoration: none;
}

.socials a{
    color:#aeaeae;
    padding-right: 10px;
    font-size: 20px;
}




.footer_text div{
 padding-bottom: 6px;
}

</style>