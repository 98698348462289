<template>

<div id="page">

    <b-container>
  
        <h1>{{ item.title  }} </h1>
        <p></p>

    <div class="video-wrap" @click="toggleplay();">
        <video 
            id="vid" 
            class="placeholder" 
            :src="require(`../assets/${item.video}`)"
            :poster="require(`../assets/${item.poster}`)"  >
        </video>

        <div v-if="showplaybutton" id="btn" class="play-btn" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311.69 311.69"><path d="M155.84,0A155.85,155.85,0,1,0,311.69,155.84,155.84,155.84,0,0,0,155.84,0Zm0,296.42A140.58,140.58,0,1,1,296.42,155.84,140.58,140.58,0,0,1,155.84,296.42Z"/><polygon points="218.79 155.84 119.22 94.34 119.22 217.34 218.79 155.84"/></svg>
        </div>          
    </div>


    <p></p>

    <div v-if="markdownItem" id="markdown">
    <vue-markdown-plus :source="markdownItem" :postrender="mdpost"></vue-markdown-plus>
    </div>

    </b-container>

</div>
    
</template>


<script>

import portfolios from '../data/portfoliolist.json';
import VueMarkdownPlus from 'vue-markdown-plus';

export default {
    components: {    
        VueMarkdownPlus
    },
    data() {
        return {
            portfolio_list : portfolios ,
            showplaybutton: true
        }
    },
    methods: {
        toggleplay: function(){
            console.log("toggle play");
            if(this.showplaybutton){
                vid.play();              
            }
            else {
                vid.pause();               
            }    
            this.showplaybutton = !this.showplaybutton;   
        },
        mdpost : function(str){
            let pattern = /(?<=src=\").*?(?=\")/gi;
            let res = str.replace(pattern, function (x) {               
                var file = x.split("/").pop();
                return require(`../assets/${file}`);                               
            });
            return res;
        }
    },
    computed: {

        item: function(){            
            let route = this.$route.params.route;
            let item = _.find( this.portfolio_list, ["route", route]);
            return item;
        },
        markdownItem :function(){
            try {
            let route = this.$route.params.route;
            let data =  require(`../data/${route}.md`).default;
            return data;    
            } catch (error) {
                return "";
            }
            
        }
    }
    
}
</script>

<style>


    .video-wrap {
      position: relative;
      /* max-width: 700px; */
      width: 100%;
      margin-bottom: 10px;
    }
    /* video poster frame or placeholder image */
    .video-wrap .placeholder {
      /* max-width: 700px; */
      width: 100%;
    }
    /* SVG play button container */
    .video-wrap .play-btn {
      position: absolute;
      /* max-width: 700px; */
      width: 100px;
      height: 100px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .video-wrap .play-btn svg {
      transition: all .2s ease;
      fill: #fff;
    }
    .video-wrap .play-btn:hover svg,
    .video-wrap .play-btn:focus svg {
      cursor: pointer;
      fill: #17e617;
    }
    /* adjust your icon size, add different breakpoints as needed */
    @media screen and (max-width: 600px) {
      .video-wrap .play-btn {
        width: 70px;
        height: 70px;
      }
    }
h1{
    font-family: 'Poppins';
}

#markdown {
    text-align: left;    
}

#markdown h3{
    padding:4px;
    background-color: #eee;
    font-size: 16px;
    color:#345;
}

#markdown p{
        padding:4px;
}

#page{
    margin-top:20px;
    margin-bottom:20px;
}

</style>