<template>
  <div class="home">

<b-carousel
    id="carousel-fade"
    style="text-shadow: 0px 0px 2px #000"
    fade
    controls
    indicators
    img-width="1024"
    img-height="480"    
  >
    <b-carousel-slide
      img-src="../assets/bart-front-crop-1-1024x537.png"
      background="#1a1a1f">

      <div class="slide_text">We Design & Build Unique Things</div>
      
      </b-carousel-slide>

    <b-carousel-slide
      img-src="https://picsum.photos/1024/480/?image=20"
    ></b-carousel-slide>

    <b-carousel-slide      
      img-src="https://picsum.photos/1024/480/?image=22"
    ></b-carousel-slide>
  </b-carousel>

  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'



export default {
  name: 'Home',
  components: {
  //  HelloWorld
  },
  data(){
    return {
      
    }
  }
}
</script>

<style >

.slide_text{  
  font-size:100px;
}


</style>
