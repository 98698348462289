<template>
  <div id="app">

  <b-row class="topbar">
    <b-col class="topleft">
       <b-img to="/" width="130" src="./assets/logo-montserrat-white-back-wit.png"></b-img>
    </b-col>

    <b-col class="topbar-items">
      <router-link to="/">Home</router-link>
      <router-link to="/portfolio">Portfolio</router-link>
      <router-link to="/about">About</router-link>
    </b-col>

    <b-col class="topright topbar-items">
      <!-- <router-link to="/cart">Cart</router-link>
      <router-link to="/login">Login</router-link> -->
    </b-col>
  </b-row>

  <router-view></router-view>

  <Footer></Footer>


  </div>
</template>


<script>

import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Footer
  }
}

</script>



<style>

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
   url(./assets/Poppins-SemiBold.ttf) format("truetype");
}

.topbar{
  background-color: #1a1a1f;
  color:#fff;
  padding: 16px;
}

.topbar-items a{
  font-weight: bold;
  color: #ffffff;
  margin-right: 4px;
  margin-left: 4px;
}

.topbar-items a:hover { 
  color:#fff;
  text-decoration: none;
}

.topbar-items a.router-link-exact-active {
  color: #42b983;
}

.topleft{
  text-align: left;
}

.topright{
  text-align: right;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family:HKGrotesk, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;  
}


</style>


